import React from "react";
import CustomCard from './CustomCard.js';
import ownerImage from './Images/Eggccessories-logo.png';
import { CardContent, Grid, Typography } from "@mui/material";
import CustomButton from './CustomButton.js';
import Owner from './Owner.js';

const About = () => {
  const description = () => {
    return (
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={10}>
          <CardContent sx={{ padding: 4 }}> {/* Added padding */}
            <Typography variant="h3" gutterBottom>
              Who are we?
            </Typography>
            <Typography variant="h5" paragraph>
            Eggccessories is based in Manchester and focuses on the local crocheting scene. Our goal is to collaborate with other crocheters to build a platform for a community centered around locally accessible crocheted products and experiences.

            </Typography>
            <CustomButton text="@Eggccessories__" link="https://www.instagram.com/eggccessories__/?igsh=bWEwcGFmY2c0cmRr&utm_source=qr"/>
          </CardContent>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginBottom: '0px', color: '#E7E1CC' }}>
       Eggccessories | Handcrafted Accessories made from recycled materials by Egi.</h1>
      <CustomCard

        description={description}
        mediaUrl={ownerImage}
        title="Eggccessories Logo"
        mediaType="image"
        split={60}
      />

    </div>

  );
};

export default About;
