import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { keyframes } from '@emotion/react';

// Define keyframes for the materialize effect
const materialize = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;


const CustomCard = ({
  title,
  description,
  mediaUrl,
  mediaType,
  split,
  customMediaComponent,
  invert = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const textWidth = Math.min(Math.max(split, 0), 100);
  const mediaWidth = 100 - textWidth;

  const textSection = (
    <Grid
      item
      xs={12}
      md={(textWidth / 100) * 12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        boxSizing: 'border-box',
      }}
    >
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        {typeof description === "function" ? description() : <Typography variant="body1">{description}</Typography>}
      </CardContent>
    </Grid>
  );

  const mediaSection = (
    <Grid
      item
      xs={12}
      md={(mediaWidth / 100) * 12}
      sx={{
        height: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        {customMediaComponent ? (
          <div style={{ width: '100%', height: '100%' }}>
            {customMediaComponent}
          </div>
        ) : mediaType === 'video' ? (
          <video
            src={mediaUrl}
            controls
            style={{ width: "100%", height: "100%", objectFit: "cover" }} // Adjusted to cover
          />
        ) : (
          <img
            src={mediaUrl}
            alt={title}
            style={{ width: "100%", height: "100%", objectFit: "cover" }} // Adjusted to cover
          />
        )}
      </div>
    </Grid>
  );

  return (
    <Card
      ref={cardRef}
      sx={{
        width: "100%",

        height: "100%",

        display: "flex",
        margin: "0",
        boxSizing: 'border-box',
        overflow: 'hidden',
        backgroundColor: '#E7E1CC',
        opacity: 0,
        animation: isVisible ? `${materialize} 1s ease forwards` : "none",
        boxShadow: 'none',
        border: 'none',
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        {invert ? (
          <>
            {mediaSection}
            {textSection}
          </>
        ) : (
          <>
            {textSection}
            {mediaSection}
          </>
        )}
</Grid>
  </Card>
);
}

export default CustomCard;